@media (max-width: 2199px) {
	h1.h4,
	h1.h4 small,
	h2.h3,
	h3.h5,
	h5.secondary,
	.rm_h2,
	.rm_h3,
	.rm_h3_huge,
	.wrap .content .main.inner-page h3,
	.home .wrap .content .main h3
	{
		display: none;
	}
}

h1.h4 {
	font-size: 1.1rem;
	background: #f2f2f2;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
	margin-top:10px;
}

h2.h3 {
	font-size: 1.1rem;
	background: #f2f2f2;
	padding: 10px;
	text-align: center;
	font-weight: 500;
	margin-bottom:0;
}

h3.h5 {
	font-size: 1.1rem;
	background: #c0c0c0;
	color: #000;
	padding: 10px;
	text-align: center;
	margin-bottom:0;
}

.rm_h2, .rm_h3{
	color: #000;
	padding: 10px;
	text-align: center;
}

.main h1.h4 small:after {
	content: " and surrounding communities";
}

.hidden {
	display: none !important;
}

.bodyimgright,
.container img.bodyimgright,
.plugin-img,
.sidebar img.bodyimgright {
	float: right;
	max-width: 30%;
}

.seo {
	display: none;
}
@media (min-width:2200px) {
	.seo {
		display: block;
	}
	h1.h4,
	h1.h4 small,
	h2.h3,
	h3.h5,
	h5.secondary,
	.rm_h2,
	.rm_h3,
	.wrap .content .main.inner-page h3:not(.rm_h3_huge),
	h3:not(.rm_h3_huge),
	.home .wrap .content .main h3	{
		display: block;
		text-align: center;
	}
}
.wrap .content .main.inner-page .page-container h3:not(.rm_h3):not(.rm_h3_huge),
.home .wrap .content .main .hc-half-content h3,
.always_show{
	display: block !important;
}
@media (min-width:5200px) {
	h3.rm_h3_huge{
		display: block;
		text-align: center;		
	}
}