// Theme Colors/Branding
$brand-primary: #104b7d;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #6f192e;
$dark: #3E4147;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #4f1221; /* 9e2441 */
$accent-2: #0b3558;
$body-font: #222;
$error: #d81e00;