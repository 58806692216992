body {
  background: white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}


// custom
.border_radius_none{
	border-radius: 0 !important;
}
.card_hover{
	transition: all 0.2s ease-in;
	&:hover{
		scale: 1.2;
	}
}

.btn{
	filter: drop-shadow(0px 0px 2px rgb(255 2555 255 / 1));
}

.background-black{
	background: #000;
}

.background-coupon {
	background: url(../../images/AdobeStock_277558526-white.jpg);
}
.page_header_bottom{
    position: relative;
	z-index: 9;
    margin-top: -4px;
}

.mountain_logo{
	position: absolute;
    z-index: 10;
    width: 100px;
    height: 100px;
    bottom: 8px;
    background: $brand-secondary;
    border-radius: 50%;
    left: 8px;
	filter: drop-shadow(2px 2px 2px rgb(255 2555 255 / 1));
}
.mountain_logo_content{
    width: 100px;
    height: 100px;
    background: $brand-secondary;
    border-radius: 50%;
	filter: drop-shadow(2px 2px 2px rgb(255 2555 255 / 1));	
	float: left;
}

.page-id-432{
	.mountain_logo_content{
		display: none !important;
	}
}

#gform_submit_button_4{
	@extend .background-secondary;
	@extend .h3;
}
/* https://css-tricks.com/adding-shadows-to-svg-icons-with-css-and-svg-filters/ */
.mountain_top_and_bottom {
  clip-path:polygon(0 3.5%, 2% 3%,7.5% 3.5%, 8% 3.7%, 8.5% 4%, 11.5% 3.1%,12% 3%,12.5% 3.1%, 15% 4%,17.7% 2.1%,18% 2%, 19.5% 2.5%,23% 3%, 28% 3%,29% 3.5%, 31% 4%,32% 5%,34.5% 3.2%,35% 3%,36% 3.3%,39% 5%, 48% 0,50% 1%,52.5% 1%,53% 1.1%,58% 5%, 60% 4.7%,63.5% 3%,64% 3.1%,64.5% 3%,65% 3.2%, 66% 3.1%,69% 4%,70% 4.3%,73% 5%,75% 4.6%,77% 4.3%,77.6% 4.1%,78% 4%,79% 4.6%,80% 5%,80.5% 4.8%,83% 3%,85% 3.7%,86% 3.5%,87% 3.7%,88% 3%,93% 3.7%, 94% 2.7%, 95% 3%,96% 2.9%,100% 3.5%, 100% 100%,98% 98%, 90% 100%, 88% 98%,83% 100%, 80% 99%,78% 100%,75% 97%,69% 99%,62% 100%,60% 100%,53% 95%,51% 95%,49% 93%,39% 100%,35% 97%,32% 96%,28% 98%,18% 98%,15% 99%,12% 98%,8% 97%,0 100%,0 100%);
  display:block;
  overflow:visible;
  position:relative;
  z-index:1;
}
.mountain_top {clip-path:polygon(0 3.5%, 2% 3%,7.5% 3.5%, 8% 3.7%, 8.5% 4%, 11.5% 3.1%,12% 3%,12.5% 3.1%, 15% 4%,17.7% 2.1%,18% 2%, 19.5% 2.5%,23% 3%, 28% 3%,29% 3.5%, 31% 4%,32% 5%,34.5% 3.2%,35% 3%,36% 3.3%,39% 5%, 48% 0,50% 1%,52.5% 1%,53% 1.1%,58% 5%, 60% 4.7%,63.5% 3%,64% 3.1%,64.5% 3%,65% 3.2%, 66% 3.1%,69% 4%,70% 4.3%,73% 5%,75% 4.6%,77% 4.3%,77.6% 4.1%,78% 4%,79% 4.6%,80% 5%,80.5% 4.8%,83% 3%,85% 3.7%,86% 3.5%,87% 3.7%,88% 3%,93% 3.7%, 94% 2.7%, 95% 3%,96% 2.9%,100% 3.5%, 100% 100%,0 100%);
  padding-top:6rem;
}

.icon_filter{
	filter: drop-shadow(0px 0px 2px rgb(255 2555 255 / 1));
}
.weather-background{
	background: #1f567c;
	margin-top: -2px;
	position: relative;
}

.notunder1500{
	.btn{
			background: #26282c;
			width: 100%;
			text-align: center;
			filter: none !important;
	}
}